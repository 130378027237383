<template>
  <div>
    
    <div v-if="trainingPlanResult">
      <v-card-title class="subtitle pb-0">Your training progress for {{trainingPlanResult.race.name}} <router-link class="ml-2" :to="{name: 'profilePlan', params: {eventId: trainingPlanResult.event.id, raceId: trainingPlanResult.race.id }}">(details)</router-link></v-card-title>
      <TrainingPlanProgress :result="trainingPlanResult.result" :event="trainingPlanResult.event" :race="trainingPlanResult.race" :training-plan="trainingPlan" :badges="badges" :visible-days="14" />
      <v-card-text>
        <v-btn color="primary" :to="{name: 'profilePlan', params: {eventId: trainingPlanResult.event.id, raceId: trainingPlanResult.race.id }}">
          View and Manage Progress
        </v-btn>
      </v-card-text>
    </div>
    
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import assetsService from "@/services/assetsService";
import eventService from "@/services/eventService";
import eventManagerService from "@/services/eventManagerService";
import TrainingPlanProgress from "@/components/TrainingPlanProgress";
import VueMarkdown from '@/components/VueMarkdown.vue'

export default {
  name: "MyTrainingPlanOverview",
  components: {
    TrainingPlanProgress,
    VueMarkdown,
  },
  props: {
    results: Array,
    adminMode: Boolean,
  },
  data() {
    return {
      badges: null,
      trainingPlan: null,
      trainingPlanResult: null,
    };
  },
  async mounted() {
      console.log('mounted');
  },
  methods: {
/*
    async loadTrainingPlan(id) {
      return (await assetsService.getTrainingPlan(id)).data;
    },
    async loadBadges(eventId, raceId) {
      return  (await eventService.getRaceBadges(eventId, raceId)).data.data;;
    },
*/
    async load() {
        console.log('RESULTS changing');
        this.trainingPlanResult = this.results && this.results.find(x => x.race && x.race.scoring === 'TRAINING_PLAN');
        //console.log('this.trainingPlanResult', this.trainingPlanResult);
        if (this.trainingPlanResult){
          this.trainingPlan = (await assetsService.getTrainingPlan(this.trainingPlanResult.race.id)).data;
          this.badges = (await eventService.getRaceBadges(this.trainingPlanResult.event.id, this.trainingPlanResult.race.id)).data.data;
        }
      }
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
    results: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      async handler(val) {
        console.log('RESULTS changing');
        this.trainingPlanResult = val && val.find(x => x.race.scoring === 'TRAINING_PLAN');
        console.log('this.trainingPlanResult', this.trainingPlanResult);
        if (this.trainingPlanResult){
          this.trainingPlan = (await assetsService.getTrainingPlan(this.trainingPlanResult.race.id)).data;
          this.badges = (await eventService.getRaceBadges(this.trainingPlanResult.event.id, this.trainingPlanResult.race.id)).data.data;
        }
      }
    }
  }

};
</script>

<style lang="scss">
</style>

