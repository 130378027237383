<template>
    <div v-if="ads && ads.length" class="text-center">
      <div class="text-muted mb-4">Sponsored messages</div>
        <a v-for="(item, idx) in ads" :key="idx" :href="item.url" target="_blank" rel="nofollow" @click="trackAdClick(item.id, idx)">
            <v-img :src="item.img" :max-height="$vuetify.breakpoint.xs ? '100px' : '150px'" max-width="100%" contain />
        </a>
    </div>
</template>

<script>

export default {
  name: "adList",
  props: {
  },
  data() {
    return {
    };
  },
  props: {
    ads: Array,
  },
  async mounted() {
  },
  methods: {
    async trackAdClick(id, idx) {
      this.$analytics.event('ad_click', { event_label: `ad_${id}`, id: id, index: idx })
    },
  },
  computed: {
  },
};
</script>

<style scoped lang="scss">
</style>