<template>
  <div v-if="badges && badges.length">
    <v-card-title class="subtitle">{{$t('profile.my-badges-title')}}</v-card-title>
    <RaceBadgesGrid :badges="badges" compact />
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import ShareButton from "@/components/ShareButton";
import RaceBadgesGrid from "@/components/RaceBadgesGrid";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "MyRaceResultsOverview",
  components: {
    ShareButton,
    RaceBadgesGrid,
  },
  props: {
    badges: Array,
  },
  data() {
    return {
      tenant: tenant,
    };
  },
  async mounted() {
    console.log('/// badges', this.badges);
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async loadData() {
      if (this.user) {
      }
    },
    
    

  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV == 'development';
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>

<style lang="scss">
</style>

